import type { ComputedRef } from 'vue';
import { unref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { AssetType } from '~/apiClient';
import { Locale } from '~/i18n/i18nConfig';

type NavMenuItem = {
    label: string;
    to: RouteLocationRaw;
    isExternal?: boolean;
};

export type UseNavMenuItemsReturn = {
    navMenuItems: ComputedRef<NavMenuItem[]>;
    sellLink: ComputedRef<string>;
};

export const useNavMenuItems = (): UseNavMenuItemsReturn => {
    const { locale } = useI18n();
    const { tt } = useTypedI18n();
    const { formatAlpRoute } = useAlpRouteUtils();

    const sellLink = computed<string>(() => (locale.value === Locale.IT ? 'https://vendi.fleequid.com/' : 'https://vendi.fleequid.com/en/sell'));

    return {
        navMenuItems: computed<NavMenuItem[]>(() => [
            {
                label: tt('menu.buy'),
                to: formatAlpRoute({ assetType: AssetType.Bus }),
            },
            {
                label: tt('menu.sell'),
                to: unref(sellLink),
                isExternal: true,
            },
            {
                label: tt('menu.faq'),
                to: 'faq',
            },
        ]),
        sellLink,
    };
};
